export function useModal() {
  const showModal = ref(false);

  function setShowModal(value: boolean) {
    showModal.value = value;
  }

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return {
    showModal,
    setShowModal,
    openModal,
    closeModal,
  };
}
