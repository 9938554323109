<template>
  <n-modal v-model:show='showModal' class='min-w-[360px] max-w-[900px]' preset='card' :title='showTitle' size='medium'
    bordered :segmented='segmented' @after-enter="$emit('show')" @after-leave="$emit('hide')">

    <n-scrollbar class='max-h-[70vh]' :style='scrollbarStyle'>
      <n-form ref='formRef' :model='formModel' :rules='rules'>
        <slot></slot>
      </n-form>
    </n-scrollbar>

    <template #footer>
      <n-space justify='end'>
        <n-button @click='handleCancel'>{{ cancelText || 'Cancel' }}</n-button>
        <n-button type='primary' @click='handleSubmit' :loading='confirmLoading'>
          {{ confirmText || (isCreate ? 'Create' : 'Update') }}
        </n-button>
      </n-space>
    </template>
  </n-modal>
</template>

<script lang='ts' setup>
import { CardSegmented, FormInst, FormRules } from 'naive-ui';

const segmented = <CardSegmented>{
  content: true,
};

const props = withDefaults(defineProps<{
  title?: string;
  fullTitle?: string;
  modal: boolean;
  form: Object;
  isCreate?: boolean;
  confirmLoading?: boolean;
  rules?: FormRules;
  confirmText?: string;
  cancelText?: string;
  submitFn?: (form?: Object) => Promise<boolean>;
  scrollbarStyle?: Object;
}>(), {
  title: '',
  confirmLoading: false,
  isCreate: true,
  rules: undefined,
});

const emit = defineEmits<{
  (event: 'update:modal', value: boolean): void;
  (event: 'update:form', value: Object): void;
  (event: 'submit:before', value: any): void; //提交前
  (event: 'submit', value: any): void; //提交
  (event: 'submit:after', success: boolean, value: any): void; //提交后
  (event: 'cancel'): void; //取消
  (event: 'show'): void; //显示
  (event: 'hide'): void;//
}>();
const showModal = computed({
  get() {
    return props.modal;
  },
  set(value) {
    emit('update:modal', value);
  },
});
const showTitle = computed(() => {
  if (props.fullTitle) {
    return props.fullTitle;
  }
  return props.isCreate ? `Create New ${props.title}` : `Update ${props.title}`;
});

const formRef = ref<FormInst | null>(null);
const formModel = computed({
  get() {
    return props.form;
  },
  set(value) {
    emit('update:form', value);
  },
});

const $message = useMessage();

function handleSubmit() {
  if (!props.rules) {
    onSubmit();
    return;
  }

  formRef.value?.validate((errors) => {
    if (!errors) {
      onSubmit();
      return;
    }
    $message.error('Please check the form');
  });
};

async function onSubmit() {
  emit('submit:before', props.form);
  if (!props.submitFn) {
    //没有单独提交函数，直接提交
    emit('submit', props.form);
    return;
  }
  const success = await props.submitFn(props.form);
  if (success) {
    showModal.value = false;
  }
  emit('submit:after', success, props.form);
}

function handleCancel() {
  showModal.value = false;
  emit('cancel');
};
</script>

<style lang='less' scoped></style>